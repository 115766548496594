import React from 'react'
import { Link } from 'gatsby'

import styles from './header.module.scss'
import logo from '../../images/logo.svg'

const Header = () => {

  return (
    <header className={styles.header}>
      <h1>
        <Link to="/" className={styles.title}>
          <img src={logo} alt="Max Squared Security" />
        </Link>
      </h1>
      <nav>
        <ul className={styles.navList}>
          <li className={styles.home}>
            <Link
              to="/"
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
            >
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="/newsletter"
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
            >
              Newsletter
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
