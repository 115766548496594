import React from 'react'
import { Link } from 'gatsby'

import Container from '../container/container'
import styles from './footer.module.scss'
import logo from '../../images/logo.svg'

const Footer = () => {
  return (
    <div className={styles.content}>
      <Container>
        <footer className={styles.footer}>
          <div className={styles.main}>
            <div className={styles.title}>
              <img src={logo} alt="Max Squared Security" />
            </div>
            <nav>
              <ul className={styles.navList}>
                <li>
                  <Link to="/" className={styles.navItem}>Home</Link>
                </li>
                <li>
                  <Link to="/services" className={styles.navItem}>Services</Link>
                </li>
                <li>
                  <Link to="/contact" className={styles.navItem}>Contact</Link>
                </li>
                <li>
                  <Link to="/newsletter" className={styles.navItem}>Newsletter</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className={styles.copyright}>
            © 2020 Max<sup>2</sup> &nbsp;Security LLC
            <span className={styles.separator}>|</span>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </footer>
      </Container>
    </div>
  )
}

export default Footer
