import React from 'react'
import PropTypes from "prop-types"

import '../../styles/index.scss'
import layoutStyles from './layout.module.scss'

import SEO from '../seo'
import Header from '../header/header'
import Footer from '../footer/footer'

const Layout = props => {
  return (
    <>
    <SEO />
    <div className={layoutStyles.wrapper}>
      <div className={layoutStyles.content}>
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
    </>
  )
}

export default Layout

Layout.propTypes = {
  title: PropTypes.string
}
